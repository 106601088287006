import { createSlice } from "@reduxjs/toolkit";
import React from "react";
import Photo from "../../../class/Photo";

export enum CarouselStatusEnum {
  play = "play",
  stop = "stop",
}

const albumCarouselSlice = createSlice({
  name: "albumCarousel",
  initialState: {
    initialized: false,
    slides: [],
    previous: {},
    current: {},
    next: {},
    index: 0,
    length: 0,
    maxIndex: 0,
    intervalId: 0,
    carouselStatus: CarouselStatusEnum.stop,
    isFullscreen: false,
    carouselThumbnailButtons: [],
  },
  reducers: {
    initSlides: (state, { payload }) => {
      state.slides = payload;
      state.current = state.slides[0] || {};
      state.length = state.slides.length;
      state.maxIndex = state.length - 1;
      state.initialized = true;
    },
    move: (state) => {
      state.index =
        state.index < 0
          ? state.maxIndex
          : state.index > state.maxIndex
          ? 0
          : state.index;

      const previousIndex = state.index > 0 ? state.index - 1 : state.maxIndex;
      const nextIndex = state.index < state.maxIndex ? state.index + 1 : 0;

      state.previous = state.slides[previousIndex];
      state.next = state.slides[nextIndex];
      state.current = state.slides[state.index];
    },
    setIndex: (state, { payload }) => {
      state.index = payload;
    },
    incrementIndex: (state) => {
      state.index += 1;
    },
    decreaseIndex: (state) => {
      state.index -= 1;
    },
    setIntervalId: (state, { payload }) => {
      clearInterval(state.intervalId);

      state.intervalId = payload;
    },
    clearIntervalId: (state) => {
      clearInterval(state.intervalId);
    },
    setCarouselStatus: (state, { payload }) => {
      if (Object.values(CarouselStatusEnum).includes(payload)) {
        state.carouselStatus = payload;
      }
    },
    setFullscreen: (state, { payload }) => {
      state.isFullscreen = payload;
    },
    setThumbnailButtons: (state, { payload }) => {
      state.carouselThumbnailButtons = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const playCarousel = () => (dispatch: any) => {
  dispatch(
    setIntervalId(
      setInterval(() => {
        dispatch(incrementIndex());
        dispatch(move());
      }, 3000)
    )
  );
};

export const stopCarousel = () => (dispatch: any) => {
  dispatch(clearIntervalId());
  dispatch(setCarouselStatus(CarouselStatusEnum.stop));
};

export const previousCarousel = () => (dispatch: any) => {
  dispatch(decreaseIndex());
  dispatch(move());
};

export const nextCarousel = () => (dispatch: any) => {
  dispatch(incrementIndex());
  dispatch(move());
};

export const goTo = (index: number) => (dispatch: any) => {
  dispatch(clearIntervalId());
  dispatch(setIndex(index));
  dispatch(move());
  // dispatch(playCarousel());
};

export const albumCarouselSelector = (state: any) => state.albumCarousel;

export const {
  initSlides,
  move,
  setIndex,
  incrementIndex,
  decreaseIndex,
  setIntervalId,
  clearIntervalId,
  setCarouselStatus,
  setFullscreen,
  setThumbnailButtons,
} = albumCarouselSlice.actions;
export default albumCarouselSlice.reducer;
