import React, { useEffect, useState } from "react";
import { useDispatch } from "../../lib/redux";
import { Link, useLocation } from "react-router-dom";

import "./albumThumbnail.scss";

import Album from "../../class/Album";
import { SizeVariant } from "../../class/Photo";
import { setIndexAlbum } from "../../lib/redux/slices/indexSlice";
import { initSlides } from "../../lib/redux/slices/albumCarouselSlice";

interface AlbumThumbnailPropsInterface {
  album: Album;
}

const AlbumThumbnail = ({
  album,
}: AlbumThumbnailPropsInterface): JSX.Element => {
  const [thumbnailPhoto, setThumbnailPhoto] = useState<SizeVariant>();
  const dispatch = useDispatch();
  const { hash } = useLocation();
  const assetsHost = process.env.REACT_APP_ASSETS_HOST;

  useEffect(() => {
    setThumbnailPhoto(album.photos[0].getSizeVariant("small"));
  }, [album]);

  useEffect(() => {
    if (hash === `#${album.slug()}`) {
      dispatch(setIndexAlbum(album));
      dispatch(initSlides(album.photos));
    }
  }, [hash, album, dispatch]);

  return (
    (thumbnailPhoto && (
      <div className="col p-0 position-relative album-thumbnail">
        <img
          className="d-block w-100"
          src={`${assetsHost}/photos/${thumbnailPhoto.shortPath}`}
          alt=""
        />

        <div className="link w-100 h-100 position-absolute top-0 start-0 text-center text-black bold d-flex flex-column justify-content-center align-items-center">
          <h3 className="w-50 m-0 pe-2">{album.title}</h3>

          <Link
            to={`#${album.slug()}`}
            className="stretched-link w-50"
          >
            Voir l'album
          </Link>
        </div>
      </div>
    )) || <></>
  );
};

export default AlbumThumbnail;
