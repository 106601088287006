import { createSlice } from "@reduxjs/toolkit";

const indexSlice = createSlice({
  name: "index",
  initialState: { album: {} },
  reducers: {
    setIndexAlbum: (state, action) => {
      state.album = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const indexSelector = (state: any) => state.index;

export const { setIndexAlbum } = indexSlice.actions;
export default indexSlice.reducer;
