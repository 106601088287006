import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "../../lib/redux";
import {
  albumCarouselSelector,
  goTo,
} from "../../lib/redux/slices/albumCarouselSlice";
import Photo from "../../class/Photo";
import { MDBBtnGroup } from "mdb-react-ui-kit";
import ThumbnailCarouselButton from "./ThumbnailCarouselButton";

interface AlbumCarouselThumbnailPropsInterface {
  photo: Photo;
  index: number;
  thumbnailContainerRef: any;
  buttons?: React.ReactNode | undefined;
}

const AlbumCarouselThumbnail = ({
  photo,
  buttons,
  thumbnailContainerRef,
  index,
}: AlbumCarouselThumbnailPropsInterface): JSX.Element => {
  const assetsHost = process.env.REACT_APP_ASSETS_HOST;
  const ref = useRef<any>();
  const [active, setActive] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { current, carouselThumbnailButtons } = useSelector(
    albumCarouselSelector
  );

  const handleSelection = (index: number) => {
    dispatch(goTo(index));
  };

  useEffect(() => {
    setActive(photo.id === current.id);
  }, [current, photo]);

  useEffect(() => {
    if (active) {
      const thumbnailContainer = thumbnailContainerRef.current;
      const currentThumb = ref.current;
      const containerWidth = thumbnailContainer.offsetWidth;
      const currentOffsetLeft = currentThumb.offsetLeft;

      const scroll =
        currentOffsetLeft > containerWidth / 2
          ? currentOffsetLeft -
            containerWidth / 2 +
            currentThumb.offsetWidth / 2
          : 0;

      thumbnailContainer.scrollTo({
        behavior: "smooth",
        left: scroll,
      });
    }
  }, [active, ref, thumbnailContainerRef]);

  return (
    <div
      key={`carousel-selector-${photo.id}`}
      id={`carousel-selector-${photo.id}`}
      ref={ref}
      className={`col-2 carousel-selector-item d-flex justify-content-center align-items-center position-relative ${
        (active && "active") || ""
      }`}
    >
      {buttons && (
        <MDBBtnGroup
          className="photo-thumbnail-controls position-absolute top-0 end-0 w-100 justify-content-end bg-light"
          size={"sm"}
        >
          {carouselThumbnailButtons.map(({ props }: any) => (
            <ThumbnailCarouselButton {...props} onClickParams={{ photo }} />
          ))}
        </MDBBtnGroup>
      )}
      <img
        className={"img-fluid"}
        src={`${assetsHost}/photos/${photo.getSizeVariant("small")?.shortPath}`}
        data-index={index}
        onClick={({
          target: {
            dataset: { index },
          },
        }: any) => {
          handleSelection(index);
        }}
        style={{ padding: "5px 6px", marginRight: 5 }}
        alt=""
      />
    </div>
  );
};

export default AlbumCarouselThumbnail;
