import React from "react";
import { MDBBtn } from "mdb-react-ui-kit";
import { ButtonProps } from "mdb-react-ui-kit/dist/types/free/components/Button/types";

interface ThumbnailCarouselButtonProps extends ButtonProps {
  onClickParams?: any | undefined;
}

const ThumbnailCarouselButton = ({
  onClickParams,
  ...props
}: ThumbnailCarouselButtonProps): JSX.Element => {
  return (
    <MDBBtn
      {...props}
      onClick={() => {
        props.onClick && props.onClick(onClickParams);
      }}
    />
  );
};

export default ThumbnailCarouselButton;
