import React, { useEffect } from "react";
import { useDispatch, useSelector } from "../../lib/redux";

import {
  fetchAlbumCollectionAsync,
  albumSelector,
} from "../../lib/redux/slices/albumSlice";
import Album from "../../class/Album";
import AlbumThumbnail from "./AlbumThumbnail";

const AlbumsThumbnails = (): JSX.Element => {
  const { data: albumCollection } = useSelector(albumSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAlbumCollectionAsync({ active: true }));
  }, [dispatch]);

  return (
    <div className="w-100 row row-cols-1 row-cols-md-2 row-cols-lg-3 m-0">
      {Object.values(albumCollection).length &&
        Object.values(albumCollection).map((album) => {
          return (
            (album instanceof Album && (
              <AlbumThumbnail key={album.id} album={album} />
            )) || <></>
          );
        })}
    </div>
  );
};

export default AlbumsThumbnails;
