import React, { useRef, useState } from "react";
import { MDBRange } from "mdb-react-ui-kit";

import { useDispatch, useSelector } from "../../lib/redux";
import AlbumCarouselThumbnail from "../../components/carousel/AlbumCarouselThumbnail";
import Photo from "../../class/Photo";
import {
  albumCarouselSelector,
  goTo,
} from "../../lib/redux/slices/albumCarouselSlice";

interface AlbumCarouselThumbnailContainerPropsInterface {
  buttons?: React.ReactNode | undefined;
  carouselThumbnailHeight?: string;
}

const AlbumCarouselThumbnailContainer = ({
  buttons,
  carouselThumbnailHeight,
}: AlbumCarouselThumbnailContainerPropsInterface): JSX.Element => {
  const thumbnailContainerRef = useRef<any>();
  const [containerStyle] = useState<object>(
    (carouselThumbnailHeight && { height: carouselThumbnailHeight }) || {}
  );
  const dispatch = useDispatch();

  const { slides, index } = useSelector(albumCarouselSelector);

  return (
    <>
      <div
        className="m-0 p-3 pt-4 position-relative carousel-thumbnails-container"
        ref={thumbnailContainerRef}
        style={containerStyle}
      >
        <div className="w-100 row flex-md-nowrap">
          {slides.map((photo: Photo, i: number) => (
            <AlbumCarouselThumbnail
              photo={photo}
              buttons={buttons}
              thumbnailContainerRef={thumbnailContainerRef}
              index={i}
            />
          ))}
        </div>
      </div>
      <div className="w-75 mx-auto d-none d-md-block">
        <MDBRange
          defaultValue={0}
          value={index}
          min="0"
          max={slides.length - 1}
          step="1"
          onChange={({ target: { value } }) => {
            dispatch(goTo(Number(value)));
          }}
        />
      </div>
    </>
  );
};

export default AlbumCarouselThumbnailContainer;
