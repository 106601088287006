import { isMobile } from "react-device-detect";

import "./home.scss";

const Home = (): JSX.Element => {
  return (
    <nav
      className={`w-100 position-relative home ${!isMobile && "d-flex"}`}
      style={{
        background:
          (!isMobile &&
            `url(${process.env.REACT_APP_ASSETS_HOST}/homeV1.jpg) center bottom / cover no-repeat`) ||
          "",
      }}
    >
      {(isMobile && (
        <div>
          <img
            src={`${process.env.REACT_APP_ASSETS_HOST}/homeV1-mob.jpg`}
            className="img-fluid"
          />
        </div>
      )) || <></>}

      <div
        className={`d-flex w-100 ${
          !isMobile && "position-absolute bottom-0 start-0"
        }`}
        style={{ borderTop: "2px solid black" }}
      >
        <a
          className="col text-center text-dark p-2 d-flex justify-content-between text-decoration-none bold invert"
          href="#gallery"
          data-mdb-smooth-scroll
        >
          <i className="fas fa-angles-down lh-base"></i>
          Gallerie
          <i className="fas fa-angles-down lh-base"></i>
        </a>
      </div>
    </nav>
  );
};

export default Home;
