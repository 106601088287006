import axios from "axios";
import ConstraintViolationList from "../class/ConstraintViolationList";
import Token from "../class/Token";
import { decodeToken } from "../tools/token";

interface CredentialsInterface {
  email: string;
  password: string;
}

export default class TokenApi {
  static readonly SESSION_ITEM = "authToken";

  static async authenticate(credentials: CredentialsInterface) {
    const token = TokenApi.getOne(credentials);

    sessionStorage.setItem(TokenApi.SESSION_ITEM, JSON.stringify(token));
  }

  static getOne(credentials: CredentialsInterface) {
    return TokenApi.sendRequest({
      data: credentials,
      baseURL: `${process.env.REACT_APP_API_BASE_HOST}/auth`,
    });
  }

  static refreshOne(renewToken: Token) {
    return TokenApi.sendRequest({
      data: { refresh_token: renewToken.refreshToken },
      baseURL: `${process.env.REACT_APP_API_BASE_HOST}/auth/token/refresh`,
    });
  }

  static async sendRequest({ data, baseURL }: any) {
    try {
      const tokenResponse = await axios({
        method: "POST",
        baseURL,
        data,
      });

      if (tokenResponse.data.token) {
        return TokenApi.buildTokenFromResponseData(tokenResponse.data);
      }
    } catch (e: any) {
      TokenApi.catchResponseError(e);
    }
  }

  static catchResponseError(error: any) {
    const {
      response: { data },
    } = error;

    if (
      data.hasOwnProperty("@context") &&
      data["@context"] === ConstraintViolationList.CONTEXT &&
      data.hasOwnProperty("hydra:description") &&
      data.hasOwnProperty("violations")
    ) {
      throw new ConstraintViolationList(
        data["hydra:description"],
        data.violations
      );
    }

    // eslint-disable-next-line
    throw error as Error;
  }

  static buildTokenFromResponseData({
    token,
    refresh_token: refreshToken,
  }: any) {
    const decodedToken = decodeToken(token);

    return new Token(token, decodedToken.exp, refreshToken);
  }

  static storeToken(token: Token) {
    sessionStorage.setItem(TokenApi.SESSION_ITEM, JSON.stringify(token));
  }

  static retrieveToken(): Token | undefined {
    const tokenJson = sessionStorage.getItem(TokenApi.SESSION_ITEM);

    if (tokenJson) {
      return Token.getInstance(JSON.parse(tokenJson));
    }
  }
}
