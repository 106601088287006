import React from "react";
import { Outlet } from "react-router-dom";
import Home from "../home/Home";

const PublicLayout = () => (
  <>
    <Home />
    <Outlet />
  </>
);

export default PublicLayout;
