import slugify from "slugify";

import { AlbumInterface } from "../Interfaces/AlbumInterface";
import Photo from "./Photo";

export default class Album implements AlbumInterface {
  active: boolean;
  description: string;
  id: string;
  title: string;
  sorting: number;
  photos: Photo[] = [];

  jsonld: object;

  constructor(data: any) {
    this.id = data.id;
    this.title = data.title;
    this.description = data.description;
    this.active = data.active;
    this.sorting = data.sorting;
    this.jsonld = data;

    this.buildPhotos(data.photos);
  }

  public slug(): string {
    return slugify(this.title, { lower: true });
  }

  private buildPhotos(photoCollection: any) {
    if (Array.isArray(photoCollection)) {
      this.photos = photoCollection.map(
        (photoData: any) => new Photo(photoData, this),
        this
      );
    }
  }
}
