import axios from "axios";
import ConstraintViolationList from "../class/ConstraintViolationList";
import TokenApi from "./TokenApi";
import Token from "../class/Token";

export default class AbstractAPI {
  endpoint: string;
  responseData?: any;
  authToken?: any;

  constructor(endpoint: string) {
    this.endpoint = endpoint;

    this.authToken = TokenApi.retrieveToken();
  }

  setEndPoint(endpoint: string) {
    this.endpoint = endpoint;
  }

  private async refreshToken() {
    await TokenApi.refreshOne(this.authToken).then(
      (token: Token | undefined) => {
        if (token) {
          this.authToken = token;
          TokenApi.storeToken(token);
        }
      }
    );
  }

  protected configurePKey(config: any) {
    const headers = { "DREAMER-PHOTO-PKEY": process.env.REACT_APP_API_PKEY };

    if (config.headers) {
      Object.assign(config.headers, headers);
    } else {
      config.headers = headers;
    }
  }

  protected async authenticate(config: any) {
    if (this.authToken && this.authToken.isExpired()) {
      await this.refreshToken();
    }

    if (this.authToken) {
      config.headers["Authorization"] = `Bearer ${this.authToken.auth}`;
    }
  }

  protected async requestData(config: any, needAuth: boolean = false) {
    this.configurePKey(config);

    if (needAuth) {
      this.authenticate(config);
    }

    try {
      this.responseData = (await axios(config)).data;
    } catch (e: any) {
      const {
        response: { data },
      } = e;

      if (
        data.hasOwnProperty("@context") &&
        data["@context"] === ConstraintViolationList.CONTEXT &&
        data.hasOwnProperty("hydra:description") &&
        data.hasOwnProperty("violations")
      ) {
        throw new ConstraintViolationList(
          data["hydra:description"],
          data.violations
        );
      }

      // eslint-disable-next-line
      throw e as Error;
    }
  }

  protected async requestRaw(config: any, needAuth: boolean = false) {
    this.configurePKey(config);

    if (needAuth) {
      this.authenticate(config);
    }

    try {
      return await axios(config);
    } catch (e) {
      console.log(e as Error);
    }
  }

  protected buildUrlParams(
    params: [string | number] | any,
    baseUrl?: string
  ): string | undefined {
    if (Array.isArray(params)) {
      const stringifyParams = params
        .map((param: string | number) => `/${param}`)
        .join();

      return baseUrl ? baseUrl + stringifyParams : stringifyParams;
    }

    if (typeof params === typeof {} && baseUrl) {
      return baseUrl
        .split("/")
        .map((urlPart: string) => {
          const matches = urlPart.match(/{(\w+)}/);

          if (
            Array.isArray(matches) &&
            matches.length > 1 &&
            params.hasOwnProperty(matches[1])
          ) {
            return params[matches[1]];
          }

          return urlPart;
        })
        .join("/");
    }
  }

  protected getHydraMember() {
    if (
      this.responseData.hasOwnProperty("hydra:member") &&
      typeof this.responseData["hydra:member"] === typeof []
    ) {
      return this.responseData["hydra:member"];
    }
  }
}
