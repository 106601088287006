import React from "react";

export interface TitleTxtPropsInterface {
  lvl: number;
  txt?: string;
  className?: string;
  children?: JSX.Element | JSX.Element[];
  id?: string;
}

interface TitlePropsInterface extends TitleTxtPropsInterface {
  children?: JSX.Element | JSX.Element[];
}

const Title = ({ lvl, id, txt, className, children }: TitlePropsInterface) => {
  const headers = [
    <h1 id={id} className={`hontary w-100 text-center mt-3 ${className}`}>
      {children || txt}
    </h1>,
    <h2
      id={id}
      className={`w-100 py-3 text-center m-0 border-bottom border-top border-2 border-dark hontary ${className}`}
    >
      {children || txt}
    </h2>,
    <h3 id={id} className={`hontary w-100 text-center mt-3 ${className}`}>
      {children || txt}
    </h3>,
    <h4 id={id} className={`hontary w-100 text-center mt-3 ${className}`}>
      {children || txt}
    </h4>,
    <h5 id={id} className={`hontary w-100 text-center mt-3 ${className}`}>
      {children || txt}
    </h5>,
    <h6 id={id} className={`hontary w-100 text-center mt-3 ${className}`}>
      {children || txt}
    </h6>,
  ];

  return (headers[lvl - 1] && headers[lvl - 1]) || <></>;
};

export default Title;
