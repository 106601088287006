import React, { useEffect } from "react";
import $ from "jquery";
import { useDispatch, useSelector } from "../../lib/redux";
import { useSwipeable } from "react-swipeable";

import "./albumCarousel.scss";

import { AlbumInterface } from "../../Interfaces/AlbumInterface";
import {
  CarouselStatusEnum,
  albumCarouselSelector,
  initSlides,
  move,
  nextCarousel,
  playCarousel,
  previousCarousel,
  setFullscreen,
  stopCarousel,
} from "../../lib/redux/slices/albumCarouselSlice";
import CarouselItem from "../../components/carousel/CarouselItem";
import AlbumCarouselThumbnailContainer from "../../components/carousel/AlbumCarouselThumbnailContainer";
import Dimension from "../../class/Dimension";
import { MDBIcon } from "mdb-react-ui-kit";
import { isMobile } from "react-device-detect";

interface AlbumCarouselPropsInterface {
  album: AlbumInterface;
  buttons?: React.ReactNode | undefined;
  carouselThumbnailHeight?: string;
}

const AlbumCarousel = ({
  album,
  buttons,
  carouselThumbnailHeight,
}: AlbumCarouselPropsInterface): JSX.Element => {
  const assetsHost = process.env.REACT_APP_ASSETS_HOST;
  const { initialized, previous, current, next, isFullscreen, carouselStatus } =
    useSelector(albumCarouselSelector);

  const dispatch = useDispatch();

  const handlePlay = () => {
    dispatch(playCarousel());
  };

  const handleStop = () => {
    dispatch(stopCarousel());
  };

  const handlePrevious = () => {
    dispatch(previousCarousel());
  };

  const handleNext = () => {
    dispatch(nextCarousel());
  };

  const handleFullscreen = () => {
    dispatch(setFullscreen(!isFullscreen));
  };

  const handleMouseEnter = () => {
    $(".arrow").css(
      "height",
      `${$(".carousel-slide.active img").outerHeight()}px`
    );
  };

  const handleResize = () => {
    const container = $(".carousel-container");
    const controls = container.find(".carousel-controls");
    const currentImg = container.find(".carousel-slide.active img");

    const imgDimension = new Dimension({
      width: currentImg.width(),
      height: currentImg.height(),
    });

    const containerHeight = container.height() || 0;
    const currentImgOffset = currentImg.offset();
    const imgParentOffset = currentImg.offsetParent().offset();

    if (
      ($(window).width() || 0) <= 575 &&
      imgDimension.width > imgDimension.height &&
      containerHeight > imgDimension.height &&
      currentImgOffset &&
      imgParentOffset
    ) {
      controls.css("top", `${currentImgOffset.top - imgParentOffset.top}px`);
    } else {
      controls.css("top", "");
    }
  };

  const handleSwipe = useSwipeable({
    onSwipedLeft: () => {
      handleNext();
    },
    onSwipedRight: () => {
      handlePrevious();
    },
  });

  window.addEventListener("resize", handleResize);

  useEffect(() => {
    if (!initialized) {
      dispatch(initSlides(album.photos));
      // dispatch(move());
    }
  }, [initialized, album, dispatch]);

  return (
    <>
      <div className="carousel-container" onMouseEnter={handleMouseEnter}>
        <div
          className={`carousel-controls ${
            (isFullscreen && "fullscreen") || ""
          }`}
        >
          <button
            className="material-symbols-outlined"
            title="Démarrer"
            onClick={handlePlay}
          >
            <MDBIcon fas icon="play-circle" />
          </button>
          <button
            className="material-symbols-outlined"
            title="Arreter"
            onClick={handleStop}
          >
            <MDBIcon fas icon="stop-circle" />
          </button>

          <button
            className="material-symbols-outlined"
            title="plain écran"
            onClick={handleFullscreen}
          >
            <MDBIcon
              fas
              icon={isFullscreen ? "compress-arrows-alt" : "arrows-alt"}
              size="sm"
            />
          </button>
        </div>

        {/* previous */}
        <div className="carousel-slide previous">
          {previous && previous.id && (
            <CarouselItem
              ident={previous.id}
              url={`${assetsHost}/photos/${
                (isMobile && previous.getSizeVariant("thumb").shortPath) ||
                previous.getSizeVariant("small").shortPath
              }`}
            />
          )}
        </div>

        {/* current */}
        <div
          className={`carousel-slide active ${
            (isFullscreen && "fullscreen") || ""
          }`}
          {...handleSwipe}
        >
          {isFullscreen && (
            <div className="carousel-controls-arrows">
              <button className={'previous'} onClick={handlePrevious}>
                <i className="fas fa-angle-left"></i>
              </button>
              <button className={'next'} onClick={handleNext}>
                <i className="fas fa-angle-right"></i>
              </button>
            </div>
          )}

          {current && current.id && (
            <CarouselItem
              ident={current.id}
              url={`${assetsHost}/photos/${
                isFullscreen
                  ? (isMobile && current.getSizeVariant("small").shortPath) ||
                    current.getSizeVariant("original").shortPath
                  : current.getSizeVariant("small").shortPath
              }`}
              // url={`${assetsHost}/photos/${
              //   isFullscreen
              //     ? (isMobile && current.getSizeVariant("small").shortPath) ||
              //       current.getSizeVariant("original").shortPath
              //     : `${assetsHost}/photos/${
              //         (isMobile && current.getSizeVariant("thumb").shortPath) ||
              //         current.getSizeVariant("small").shortPath
              //       }`
              // }
              // `}
              onLoad={handleResize}
            />
          )}
        </div>

        {/* next */}
        <div className="carousel-slide next">
          {next && next.id && (
            <CarouselItem
              ident={next.id}
              url={`${assetsHost}/photos/${
                (isMobile && next.getSizeVariant("thumb").shortPath) ||
                next.getSizeVariant("small").shortPath
              }`}
            />
          )}
        </div>
      </div>

      <AlbumCarouselThumbnailContainer
        buttons={buttons}
        carouselThumbnailHeight={carouselThumbnailHeight}
      />
    </>
  );
};

export default AlbumCarousel;
