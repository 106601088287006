import Title from "../title/Title";

interface IntroPropsInterface {
  title: string;
  firstTxt: string;
  src: string;
}

const IntroElement = ({ title, firstTxt, src }: IntroPropsInterface) => {
  return (
    <>
      <Title lvl={1} txt={title} />

      <section className="w-100 d-flex justify-content-center mb-3">
        <div className="d-flex flex-column flex-lg-row justify-content-around align-items-center">
          <div className="col-12 col-md-3">
            <img className="d-block w-100 px-3" src={src} alt={""} />
          </div>

          <p className="col-12 col-lg-6 p-8 lead">{firstTxt}</p>
        </div>
      </section>
    </>
  );
};

export default IntroElement;
