import AbstractAPI from "./AbstractAPI";
import Album from "../class/Album";

export default class AlbumAPI extends AbstractAPI {
  constructor() {
    super(`${process.env.REACT_APP_API_BASE_URL}/album_lychees`);
  }

  async getCollection(filters: any | null = null) {
    await this.requestData({
      method: "GET",
      baseURL: this.endpoint,
      params: filters,
    });

    if (
      this.responseData.hasOwnProperty("hydra:member") &&
      typeof this.responseData["hydra:member"] === typeof []
    ) {
      const member = this.getHydraMember();

      return member.map((data: any) => new Album(data));
    }
  }

  async getOne(id: string) {
    await this.requestData({
      method: "GET",
      baseURL: this.buildUrlParams([id], this.endpoint),
    });

    if (this.responseData) {
      return new Album(this.responseData);
    }
  }
}
