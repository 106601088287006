import React, { ReactEventHandler } from "react";

interface CarouselItemPropsInterface {
  ident: string;
  url: string;
  onLoad?: ReactEventHandler;
}

const CarouselItem = ({
  ident,
  url,
  onLoad,
}: CarouselItemPropsInterface): JSX.Element => {
  return (
    <>
      <img
        id={`carousel-item-${ident}`}
        className="d-block"
        src={url}
        alt=""
        onLoad={onLoad}
      />
    </>
  );
};

export default CarouselItem;
