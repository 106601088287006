import React from "react";
import ReactDOM from "react-dom/client";
import { isMobile } from "react-device-detect";

import "./assets/app.scss";

import reportWebVitals from "./reportWebVitals";
import { Providers } from "./lib/providers";
import Router from "./router";

const body = document.getElementById("root") as HTMLElement;

if (isMobile) {
  body.style.maxWidth = "992px";
  body.style.margin = "auto";
}

const root = ReactDOM.createRoot(body);
root.render(
  <React.StrictMode>
    <Providers>
      <Router />
    </Providers>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
