import IntroElement from "../../components/intro/IntroElement";
import AlbumsThumbnails from "../../components/gallery/AlbumsThumbnails";
import AlbumDisplay from "../../components/album/AlbumDisplay";
import { displayTypeEnum } from "../../enum/DisplayTypeEnum";
import { useSelector } from "../../lib/redux";
import { indexSelector } from "../../lib/redux/slices/indexSlice";
import Title from "../../components/title/Title";
import Album from "../../class/Album";

const Index = (): JSX.Element => {
  const { album } = useSelector(indexSelector);

  return (
    <>
      <IntroElement
        title="Le rêve devient une forme d'expression, un langage"
        src={`${process.env.REACT_APP_ASSETS_HOST}/intro.jpg`}
        firstTxt={
          "Bienvenue sur Dreamer Photo, où le rêve devient une forme d'expression, un langage capturé à travers l'objectif. Plongez dans un univers où chaque image raconte une histoire, où la lumière et l'ombre dansent en harmonie pour créer des moments intemporels. Ma passion pour la photographie me permet de capturer l'émotion et la beauté et de le faire partager. Explorez ma galerie en ligne sur dreamer-photo.fr et laissez-vous emporter par le pouvoir évocateur de chaque cliché où les rêves prennent vie."
        }
      />

      <Title id="gallery" lvl={2} txt={"Galerie"} />

      <AlbumsThumbnails />

      {album instanceof Album && (
        <AlbumDisplay displayType={displayTypeEnum.page} album={album} />
      )}
    </>
  );
};

export default Index;
