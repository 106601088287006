import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./pages/index/Index";
import PublicLayout from "./components/layout/PublicLayout";

const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PublicLayout />}>
          <Route path="" element={<Index />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
