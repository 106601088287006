export default class ConstraintViolationList implements Error {
  static readonly CONTEXT = "/api/contexts/ConstraintViolationList";

  name: string;
  message: string;
  stack?: string | undefined;
  cause?: unknown;

  violations: object[] = [];

  constructor(description: string, violations: object[] = []) {
    this.name = ConstraintViolationList.CONTEXT;
    this.message = description
    this.violations = violations;
  }
  
  
}
