import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AlbumAPI from "../../../API/AlbumAPI";
import Album from "../../../class/Album";

export const fetchAlbumCollectionAsync = createAsyncThunk(
  "album/fetchCollection",
  async (filters: any | null = null) =>
    await new AlbumAPI().getCollection(filters)
);

export const fetchAlbumAsync = createAsyncThunk(
  "album/fetch",
  async (id: string | undefined) => {
    if (typeof id === "string") {
      return await new AlbumAPI().getOne(id);
    }
  }
);

const albumSlice = createSlice({
  name: "album",
  initialState: {
    data: {},
    sortingMax: 0,
    thumbnail: {},
    photoRequestSent: 0,
  },
  reducers: {
    setSortingMax: (state, action) => {
      state.sortingMax = action.payload;
    },
    setThumbnail: (state, action) => {
      state.thumbnail = action.payload;
    },
    addPhotoRequestSent: (state) => {
      state.photoRequestSent++;
    },
    resetPhotoRequestSent: (state) => {
      state.photoRequestSent = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlbumCollectionAsync["fulfilled"], (state, action) => {
        action.payload.forEach((album: Album) => {
          state.data = { ...state.data, [btoa(album.id)]: album };
        });
      })
      .addCase(fetchAlbumAsync["fulfilled"], (state, action) => {
        if (action.payload instanceof Album) {
          state.data = action.payload;
        }
      })
      ;
  },
});

export const albumSelector = (state: any) => state.album;
export const albumIdSelector = (state: any, id: string) => btoa(id);

export const {
  setSortingMax,
  setThumbnail,
  addPhotoRequestSent,
  resetPhotoRequestSent,
} = albumSlice.actions;
export default albumSlice.reducer;
