import { AlbumInterface } from "../Interfaces/AlbumInterface";
import Album from "./Album";

type SizeVariantType = "original" | "small" | "thumb2x" | "thumb";
export type SizeVariant = {
  shortPath: string;
  width: number;
  height: number;
};

export default class Photo {
  id?: string;
  sizeVariants: SizeVariant[] = [];
  sorting: number = 0;
  album?: AlbumInterface;

  constructor(data: any, album?: Album) {
    this.id = data.id || undefined;
    this.sizeVariants = data.sizeVariants as SizeVariant[];
    this.sorting = data.sorting || 0;

    this.album =
      album instanceof Album
        ? album
        : data.album
        ? new Album(data.album)
        : undefined;
  }

  getSizeVariant(type: SizeVariantType): SizeVariant | undefined {
    return this.sizeVariants.find(
      (sizeVariant: SizeVariant) =>
        sizeVariant.shortPath.split("/").shift() === type
    );
  }
}
