import { AlbumInterface } from "../../Interfaces/AlbumInterface";
import AlbumCarousel from "../../components/carousel/AlbumCarousel";
import Title from "../../components/title/Title";
import {useEffect, useState} from "react";

interface AlbumPagePropsInterface {
  album: AlbumInterface;
}

const AlbumPage = ({ album }: AlbumPagePropsInterface): JSX.Element => {
    useEffect(() => {
        document.querySelector('.album-display')?.scrollIntoView({
            behavior: 'smooth'
        });
    }, [album]);

  return (
    <>
      <div className="album-display vh-100">
        <Title id={album.slug()} lvl={3} txt={album.title} />

        <AlbumCarousel album={album} />
      </div>
    </>
  );
};

export default AlbumPage;
