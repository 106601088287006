import { displayTypeEnum } from "../../enum/DisplayTypeEnum";
import { Link } from "react-router-dom";
import { AlbumInterface } from "../../Interfaces/AlbumInterface";
import AlbumPage from "../../pages/album/AlbumPage";

interface AlbumDisplayInterface {
  displayType: displayTypeEnum;
  album: AlbumInterface;
}

const AlbumDisplay = ({
  album,
  displayType,
}: AlbumDisplayInterface): JSX.Element => {
  const displayTableRow = () => {
    const { id, title, description } = album;

    return (
      <>
        <td>{id}</td>
        <td>{title}</td>
        <td>{description}</td>
        <td>
          <Link to={`/album/edit/${id}`}>
            <button>&#9998;</button>
          </Link>
        </td>
      </>
    );
  };

  switch (displayType) {
    case displayTypeEnum.tableRow:
      return displayTableRow();
    case displayTypeEnum.page:
      return AlbumPage({ album });
    default:
      return <></>;
  }
};

export default AlbumDisplay;
